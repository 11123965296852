import React from 'react';
import { Flex } from 'theme-ui';
import SEO from '../components/seo';

const Success = () => (
  <>
    <SEO title="Form Submisson Success" />
    <Flex sx={{ margin: '0 auto', maxWidth: 'mainBodyContainer', justifyContent: 'center', flexDirection: 'column' }}>
      <h2>You did it!</h2>
      <p>Thank you so much for RSVPing to our wedding! We cannot wait to celebrate with you :) </p>
    </Flex>
  </>
);

export default Success;
